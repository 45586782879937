import React from "react";
import parse from "html-react-parser";
import IframeResizer from 'iframe-resizer-react'
import { useMediaQuery } from "react-responsive";

//styles
import "./format-15.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

const Format15 = (props) => {
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-15" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        <Row className="desc">
          <Col className="side-content">{props.item.short_desc === null || props.item.short_desc === "" ? "" :  parse(props.item.short_desc)}</Col>
          <Col className="side-content">{props.item.desc === null || props.item.desc === "" ? "" : parse(props.item.desc)}</Col>
        </Row>
      </div>


      {/* IFRAME */}
      {
        checkValue(props.item.link_video) ? 
        <IframeResizer
          log
          scrolling="yes"
          src={props.item.link_video}
          style={{ width: '100%', minWidth: '100%', height: '100vh', marginTop: "2rem"}}
        /> :
        checkValue(props.item.image) ?
        <Row className="justify-content-center box-image">
        <img src={checkImageMobile()} className="img-fluid" alt="" />
      </Row> :
        null
      }

    </Container>
  );
};

export default Format15;
