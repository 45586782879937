import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { withRouter } from "react-router";
import Slider from "react-slick";

import Loader from "react-loader-spinner";

//Format
import Format from "../../components/format/format.combine";

//Page
import PageGrievanceForm from "../grievance-form/grievance-form.component";
import PageGrievanceTable from "../grievance-table/grievance-table.component";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

//services
import CollectionsServices from "../../services/collections.services";
import VideoModal from "../../components/video-modal/video-modal.component";

//styles
import "./generated.style.scss";
import PdfModal from "../../components/pdf-modal/pdf-modal.component";
import FormatFormEmail from "../../components/format/format-form-email/format-form-email.component";

const PageGenerated = (props) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  const [dataPage, setDataPage] = useState(null);
  const [modalShowVideo, setModalShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContentPage();
  }, [props.location.pathname]);

  const getContentPage = async () => {
    setLoading(true);
    let response = await CollectionsServices.getContentPage(props.page.id);
    setLoading(false);
    
    let res = {...response.data};
    let news_update = {};
      
    if(res.news_update){
      news_update.name = res.news_update.title;
      news_update.desc = res.news_update.subtitle;
      news_update.data = res.news_update.data;
    }
    res.news_update = news_update;
    
    setDataPage(res);
  };

  if (loading) {
    return <div className="box-loader-content">
    <Loader type="TailSpin" color="#81C42A" height={100} width={100} />
  </div>;;
  }

  const renderFormatedContent = (item, key, idContent) => {
    switch (item.format) {
      case 1:
        return <Format.Format1 keyUniq={key} item={item} />;

      case 2:
        return <Format.Format2 keyUniq={key} item={item} />;

      case 3:
        return <Format.Format3 keyUniq={key} item={item} />;

      case 4:
        return <Format.Format4 keyUniq={key} item={item} />;

      case 5:
        return <Format.Format5 keyUniq={key} item={item} />;

      case 6:
        return (
          <Format.Format6
            keyUniq={key}
            item={item}
            handleShowModalVideo={handleShowModalVideo}
          />
        );

      case 7:
        return <Format.Format7 keyUniq={key} item={item} />;

      case 8:
        return <Format.Format8 keyUniq={key} item={item} />;

      case 9:
        return <Format.Format9 keyUniq={key} item={item} />;

      case 10:
        return <Format.Format10 keyUniq={key} item={item} />;

      case 11:
        return <Format.Format11 keyUniq={key} item={item} />;

      case 12:
        return <Format.Format12 keyUniq={key} item={item} idContent={idContent} />;

      case 13:
        return <Format.Format13 keyUniq={key} item={item} />;

      case 14:
        return (
          <Format.Format14
            keyUniq={key}
            item={item}
            handleShowModalVideo={handleShowModalVideo}
            idContent={idContent} 
          />
        );

      case 15:
        return <Format.Format15 keyUniq={key} item={item} />;

      case 16:
        return <Format.Format16 keyUniq={key} item={item} idContent={idContent} />;

      case 17:
        return <Format.Format17 keyUniq={key} item={item} idContent={idContent} />;

      default:
        return null;
    }
  };

  const renderSpesificPage = (data) => {
    switch (data?.type) {
      case 3:
        return <PageGrievanceForm item={data} />;

      case 4:
        return <PageGrievanceTable item={data} />;
    }
  };

  const renderNewsUpdate = (item) => {
    return(
      <Format.FormatNewsUpdate keyUniq={"key-news-update"} item={item} />
    )
  }

  const renderFormHome = (data) => {
    let item = {
      title : data.title,
      subtitle1 : data.subtitle,
      subtitle2 : data.subtitle2,
      submit: data.submit,
      body: data.body,
      image : "/assets/images/img-form-email.png"
    };

    

    return(
      <Format.FormatFormEmail keyUniq={"key-form-email"} item={item} />
    )
  }

  const handleShowModalVideo = (val, url) => {
    if (val && url && url !== null && url !== "") {
      setVideoUrl(url);
      setModalShowVideo(true);
    } else {
      setModalShowVideo(false);
    }
  };

  const BannerSlide = (props) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      autoplay: true
    };

    const handleGoToLink = (link) => {
      window.location.replace(link);
    }

    return (
      <div className="box-banner-slideshow">
        <Slider {...settings} className="slider-slideshow">
          {props.dataBanner.map((item, key) => (
            <div key={key} className="content-slide">
              <h1 className="banner-title" onClick={() => handleGoToLink(item.link)}>{item.name}</h1>
              <img src={renderPageBanner(item?.banner, item?.banner_mobile)} alt="" />
            </div>
          ))}
        </Slider>
      </div>
    );
  };


  const renderPageBanner = (bDesktop, bMobile) => {
    if(isTabletOrMobileDevice){
      if(!bMobile || !bMobile === null || bMobile === ""){
        return bDesktop;
      }
      else{
        return bMobile;
      }
    }
    else{
      return bDesktop;
    }
  }

  return (
    <React.Fragment>
      {/*BANNDER SLIDE*/}
      {dataPage?.banner_home && dataPage?.type === 1 && <BannerSlide dataBanner={dataPage?.banner_home} />}

      {/* VIDEO MODAL */}
      <VideoModal
        backdrop={"static"}
        show={modalShowVideo}
        onHide={() => setModalShowVideo(false)}
        videoUrl={videoUrl}
      />

      {/* BANNER CONTENT */}
      {dataPage?.banner && dataPage?.type !== 1 && (
        <img src={renderPageBanner(dataPage?.banner, dataPage?.banner_mobile)} className="banner-page" alt="" />
      )}

      {/* RENDER */}
      {dataPage?.type === 1 ||
      dataPage?.type === 2 ||
      dataPage?.type === 5 ||
      dataPage?.type === 6 ||
      dataPage?.type === 7
        ? dataPage?.content.map((item, key) => {
            return renderFormatedContent(item, key, dataPage.id);
          })
        : renderSpesificPage(dataPage)}

      {/* NEWS UPDATE */}
      {
        dataPage?.news_update && renderNewsUpdate(dataPage?.news_update)
      }


      {/* FORM EMAIL */}
      {
        dataPage?.contact_us && renderFormHome(dataPage?.contact_us)
      }

      {/* IMG FOOTER */}
      {dataPage?.img_footer && dataPage?.img_footer !== "" && (
        <img src={dataPage?.img_footer} className="banner-footer" alt="" />
      )}
    </React.Fragment>
  );
};

export default withRouter(PageGenerated);
