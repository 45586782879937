import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Utils from "../../../utils/utils";

//styles
import "./format-17.style.scss";

//React Bootstrap
import { Container, Row, Tabs, Tab } from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import { useMediaQuery } from "react-responsive";
import CompFilter from "../../filter/filter.component";
import collectionsServices from "../../../services/collections.services";

const Format17 = (props) => {
  const [keyTab, setKeyTab] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payLoadFilter, setPayloadFilter] = useState(null);

  useEffect(() => {
    if (props?.item?.data) {
      setData(props.item);
    }
  }, [props.item]);

  const getContentPagePagination = async (isFilter = false, payload = null, itemTab=null) => {
    setPayloadFilter(payload);
    
    if(isFilter){
      setLoading(true);
      let response = await collectionsServices.getContentPage(props.idContent, payload);
      setData(response.data.content[0])
      setLoading(false);
    }
    else{
      setLoading(true);
      let response = await collectionsServices.getContentPagePagination(
        itemTab.id,
        isFilter ? 1 : itemTab.data.currentpage + 1,
        payload
      );

      let _data = { ...itemTab.data };
      let res = { ...response.data };

      _data.currentpage = res.dataCurrentPage;
      _data.hasMorePages = res.hasMorePages;

      if (isFilter) {
        _data.result = [...res.data];
      } else {
        _data.result = [..._data.result, ...res.data];
      }

      let allData = {...data};
      let _allData_data = {...allData.data};


      let allTab = [..._allData_data.tab];

      let currTab = allTab.find(item => item.id === itemTab.id);
      currTab.data = _data;

      allTab.map((tab) => {
        if(tab.id === currTab.id){
          tab = currTab;
        }
      })

      allData.data.tab = allTab;
      allData.data = _allData_data;

      setData(allData);
      setLoading(false);
    }

    
  };

  useEffect(() => {
    setKeyTab(props.item.data.selected);
  }, [props.item.data.selected]);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const openPdf = (link) => {
    if (link && link !== null && link !== "") window.open(link, "_blank");
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if (isTabletOrMobileDevice && checkValue(data.image_mobile)) {
      return data.image_mobile;
    } else {
      return data.image;
    }
  };

  return (
    <Container bsPrefix="container-xl format-17" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(data.name) && (
          <Row className="title">
            <h1>{data.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(data.desc) && (
          <Row className="desc">{parse(data.desc)}</Row>
        )}

        {/* FILTER */}
        <CompFilter
          month={false}
          order={false}
          onFilter={getContentPagePagination}
          idContent={props.idContent}
        />

        {/* DATA */}
        {data?.data && data?.data?.tab && data?.data?.tab?.length > 0 && (
          <Tabs
            activeKey={keyTab}
            onSelect={(k) => {
              setKeyTab(k);
            }}
            className="justify-content-center tab-menu"
          >
            {data.data.tab.map((tab, uniq) => {
              return (
                <Tab eventKey={tab.id} title={tab.name}>
                  <div className="content-data">
                    {tab?.data?.result?.map((item, uniq) => {
                      return (
                        <div
                          className="grid-data"
                          onClick={() => openPdf(item.link_download)}
                        >
                          <div className="grid-desc">
                            <>
                              <span className="dot"></span>
                              {!isTabletOrMobileDevice && (
                                <>
                                  <span className="date">{item.date}</span>
                                  <span className="divider">-</span>
                                </>
                              )}
                              <span className="name">{item.name}</span>
                            </>
                          </div>
                          {isTabletOrMobileDevice && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <label style={{ marginBottom: 0 }}>
                                  Download PDF
                                </label>
                                <img
                                  src={"/assets/images/icon-download.png"}
                                  alt=""
                                  style={{ maxHeight: 40, marginLeft: 8 }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {tab.data.hasMorePages && (
                    <div className="content-download">
                      <ButtonOutlineGreen
                        text={loading ? "Loading" : Utils.getLabel("load_more")}
                        onClick={
                          loading ? () => {} : () => getContentPagePagination(false, payLoadFilter, tab)
                        }
                      />
                    </div>
                  )}
                </Tab>
              );
            })}
          </Tabs>
        )}
      </div>
    </Container>
  );
};

export default Format17;
