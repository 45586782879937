import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-1.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

const Format1 = (props) => {
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-1" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}
      </div>

      {/* IMAGE */}
      {checkValue(props.item.image) && (
        <Row className="justify-content-center">
          <img src={checkImageMobile()} className="img-fluid" alt="" />
        </Row>
      )}
    </Container>
  );
};

export default Format1;
