import axios from "axios";
import LocalstorageService from "./localstorage.service";

const http = () => {
  let lang = LocalstorageService.get("lang");
  if(!lang)
  {
    lang = "en";
    LocalstorageService.set("lang", lang);
  }
  let headers = {
    "Content-type": "application/json",
    "lang": lang
  };

  return axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: headers,
  });
};

export default { http };
