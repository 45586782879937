import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-13.style.scss";

//React Bootstrap
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import utils from "../../../utils/utils";

const Format13 = (props) => {
  const [keyTab, setKeyTab] = useState("");

  useEffect(() => {
    setKeyTab(props.item.data.selected);
  }, [props.item.data.selected]);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-13" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}

        <div className="filter">
          <span className="title">{props.item.name} List</span>
          <div className="action-select">
            <DropdownButton id="dropdown-basic-button" title="Year - All">
              <Dropdown.Item href="#">ALL</Dropdown.Item>
              <Dropdown.Item href="#">2020</Dropdown.Item>
              <Dropdown.Item href="#">2019</Dropdown.Item>
              <Dropdown.Item href="#">2018</Dropdown.Item>
              <Dropdown.Item href="#">2017</Dropdown.Item>
              <Dropdown.Item href="#">2016</Dropdown.Item>
              <Dropdown.Item href="#">2015</Dropdown.Item>
            </DropdownButton>
            <DropdownButton id="dropdown-basic-button" title="Month - All">
              <Dropdown.Item href="#">ALL</Dropdown.Item>
              <Dropdown.Item href="#">January</Dropdown.Item>
              <Dropdown.Item href="#">February</Dropdown.Item>
              <Dropdown.Item href="#">March</Dropdown.Item>
              <Dropdown.Item href="#">April</Dropdown.Item>
              <Dropdown.Item href="#">May</Dropdown.Item>
              <Dropdown.Item href="#">June</Dropdown.Item>
              <Dropdown.Item href="#">July</Dropdown.Item>
              <Dropdown.Item href="#">September</Dropdown.Item>
              <Dropdown.Item href="#">October</Dropdown.Item>
              <Dropdown.Item href="#">November</Dropdown.Item>
              <Dropdown.Item href="#">JuDecemberne</Dropdown.Item>
              
            </DropdownButton>
            <DropdownButton id="dropdown-basic-button" title="Sort - Newest">
              <Dropdown.Item href="#">Newest</Dropdown.Item>
              <Dropdown.Item href="#">Latest</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        {/* DATA */}
        {props.item.data && props.item.data.length > 0 && (
          <div className="content-data">
            {props.item.data.map((item) => {
              return (
                <div className="grid-data">
                  <div className="grid-desc">
                    <span className="dot"></span>
                    <span className="date">{item.date}</span>
                    <span className="divider">-</span>
                    <span className="name">{item.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="action">
        <ButtonOutlineGreen text={utils.getLabel("load_more")} onClick={() => {}} />
      </div>

      {/* IMAGE */}
      {checkValue(props.item.image) && (
        <Row className="justify-content-center">
          <img src={checkImageMobile()} className="img-fluid" alt="" />
        </Row>
      )}
    </Container>
  );
};

export default Format13;
