import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-3.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

const checkValue = (val) => {
  return val && val !== "" ? true : false;
}


const Format3 = (props) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-3" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        <Row className="title">
          <h1>{props.item.name}</h1>
        </Row>

        {/* SUBTITLE */}
        {checkValue(props.item.subname) && (
          <Row className="subtitle">
            <span>
            {parse(props.item.subname)}
            </span>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}
      </div>

      {/* IMAGE */}
      {checkValue(props.item.image) && (
         <img src={checkImageMobile()} className="img-fluid" alt="" />
      )}
    </Container>
  );
};

export default Format3;
