import React from "react";

import "./button.style.scss";

export const ButtonFill = (props) => {
  const handleOnclick = () => {
    props.onClick();
  };

  return (
    <button className='button-fill' onClick={() => handleOnclick()}>
      {props.text}
    </button>
  );
};

export const ButtonFillGreen = (props) => {
  const handleOnclick = () => {
    props.onClick();
  };

  return (
    <button className='button-fill-green' onClick={() => handleOnclick()}>
      {props.text}
    </button>
  );
};

export const ButtonOutlineGreen = (props) => {
  const handleOnclick = () => {
    props.onClick();
  };

  return (
    <button className='button-outline-green' onClick={() => handleOnclick()}>
      {props.text}
    </button>
  );
};
