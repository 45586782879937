import React, { useState } from "react";
import parse from "html-react-parser";
import { Link, useHistory, withRouter } from "react-router-dom";

//styles
import "./footer.style.scss";

//React Bootstrap
import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";

const CompFooter = (props) => {
  let history = useHistory();

  const changePage = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <Container bsPrefix="container-xl footer-mobile">
        <div className="footer-logo">
          <img
            src="/assets/images/logo-footer.png"
            className="d-inline-block align-top"
            alt="logo"
            onClick={() => changePage("/")}
          />
        </div>
        <div className="footer-content">
          <h5>{props.footer.title}</h5>

          {parse(props.footer.address)}

          {props.footer.contact.map((item) => (
            <label>
              <span className="title">{item.name}:</span>
              <span className="value">{item.value}</span>
            </label>
          ))}
        </div>
        <div className="menu-social-media">
              {props.footer.social_media.map((item) => {
                if (item.url && item.url !== "") {
                  return (
                    <img
                      onClick={() => window.open(item.url)}
                      src={item.img}
                      className="d-inline-block align-top"
                      alt={`logo-${item.id}`}
                    />
                  );
                }
              })}
            </div>
            <span className="copyright">{props.footer.copyright}</span>
      </Container>

      <Container bsPrefix="container-xl footer-desktop">
        <Row>
          <Col className="footer-content">
            <h5>{props.footer.title}</h5>

            {parse(props.footer.address)}

            <div className="contact">
              {props.footer.contact.map((item) => (
                <label>
                  <span className="title">{item.name}:</span>
                  <span className="value">{item.value}</span>
                </label>
              ))}
            </div>
          </Col>
          <Col className="footer-logo">
            <img
              src="/assets/images/logo-footer.png"
              className="d-inline-block align-top"
              alt="logo"
              onClick={() => changePage("/")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="box-quick-links">
            <span className="title">{props.footer.menu.title}</span>
            <div className="footer-menu">
              {props.footer.menu.data.map((item) => (
                <span onClick={() => changePage(`/${item.nicename.toLowerCase()}`)}>
                  {item.name}
                </span>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="box-social-media">
            <span className="title">{props.footer.copyright}</span>
            <div className="menu-social-media">
              {props.footer.social_media.map((item) => {
                if (item.url && item.url !== "") {
                  return (
                    <img
                      onClick={() => window.open(item.url)}
                      src={item.img}
                      className="d-inline-block align-top"
                      alt={`logo-${item.id}`}
                    />
                  );
                }
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(CompFooter);
