import React, { useContext, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import * as Icon from "react-feather";

//styles
import "./header.style.scss";

//React Bootstrap
import {
  Nav,
  Navbar,
  Container,
  Row,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";

//services
import LocalstorageService from "../../services/localstorage.service";

const CompHeader = (props) => {
  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleBurgerMenu = () => {
    if (showMobileMenu) {
      setShowMobileMenu(false);
    } else {
      setShowMobileMenu(true);
    }
  };

  const handleChangeLang = (lang) => {
    if (LocalstorageService.get("lang") !== lang)
      LocalstorageService.set("lang", lang);
  };

  const changePage = (path) => {
    history.push(path);
  };

  const searchPage = (path) => {
    setShowSearch(false);
    history.push(path);
  };

  const setPathName = (name) => {
    return name.replace(/ /g, "-");
  };

  const handleMenuActiveMobileLevel2 = (submenu) => {
    if (
      props.location.pathname ===
      `/${setPathName(submenu.nicename.toLowerCase())}`
    ) {
      return "active";
    } else {
      let currentPath = submenu.submenu.find((sub) => {
        if (
          props.location.pathname ===
          `/${setPathName(sub.nicename.toLowerCase())}`
        ) {
          return setPathName(sub.nicename.toLowerCase());
        }
      });

      if (currentPath) return "active";
      else return "";
    }
  };

  const handleMenuActiveMobileLevel3 = (nicename) => {
    if (props.location.pathname === `/${setPathName(nicename.toLowerCase())}`)
      return "active";
    else return "";
  };

  const handleMenuActive = (item) => {
    if (
      props.location.pathname === `/${setPathName(item.nicename.toLowerCase())}`
    ) {
      return "active";
    }

    let currentPath = item.submenu.find((submenu) => {
      if (
        props.location.pathname ===
        `/${setPathName(submenu.nicename.toLowerCase())}`
      ) {
        return setPathName(submenu.nicename.toLowerCase());
      } else {
        return submenu.submenu.find((sub) => {
          if (
            props.location.pathname ===
            `/${setPathName(sub.nicename.toLowerCase())}`
          ) {
            return setPathName(sub.nicename.toLowerCase());
          }
        });
      }
    });

    if (currentPath) return "active";
    else return "";
  };

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    classText,
    classActive,
    canToggle,
    nicename,
  }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    const handleChangePageFromMobile = () => {
      changePage(`/${setPathName(nicename.toLowerCase())}`);

      setShowMobileMenu(false);
    };

    return (
      <button
        type="button"
        onClick={
          canToggle ? decoratedOnClick : () => handleChangePageFromMobile()
        }
        className={`text-menu ${classText} ${classActive}`}
      >
        {children}
      </button>
    );
  };

  return (
    <React.Fragment>
      <div className="page-container header-mobile">
        <div className="box-nav">
          <div className="box-end">
            <div className="burger" onClick={() => handleBurgerMenu()}>
              <Icon.Menu size={30} />
            </div>
          </div>

          <div className="logo-mobile">
            <img
              src="/assets/images/logo-footer.png"
              alt="logo"
              onClick={() => changePage("/")}
            />
          </div>
          <div className="box-end">
            <div className="search-icon" onClick={() => searchPage("/search")}>
              <Icon.Search color={"#FFFFFF"} size={24} />
            </div>
          </div>
        </div>

        <div
          className={`box-menu ${showMobileMenu ? "active" : ""}`}
          style={{ height: window.innerHeight - 80 }}
        >
          <div className={`list-menu ${showMobileMenu ? "active" : ""}`}>
            <Accordion defaultActiveKey="0">
              {props.menu.map((item, key) => {
                return (
                  <Card>
                    <Card.Header>
                      <ContextAwareToggle
                        eventKey={key.toString()}
                        canToggle={item.submenu.length > 0 ? true : false}
                        classText="level1"
                        classActive={handleMenuActive(item)}
                        nicename={item.nicename}
                      >
                        {item.name}
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={key.toString()}>
                      <Accordion defaultActiveKey="0">
                        {item.submenu.map((submenu, submenuKey) => {
                          return (
                            <Card>
                              <Card.Header>
                                <ContextAwareToggle
                                  eventKey={submenuKey.toString()}
                                  classText="level2"
                                  classActive={handleMenuActiveMobileLevel2(
                                    submenu
                                  )}
                                  canToggle={
                                    submenu.submenu.length > 0 ? true : false
                                  }
                                  nicename={submenu.nicename}
                                >
                                  {submenu.name}
                                </ContextAwareToggle>
                              </Card.Header>
                              <Accordion.Collapse
                                eventKey={submenuKey.toString()}
                              >
                                <Accordion defaultActiveKey="0">
                                  {submenu.submenu.map((sub, subKey) => {
                                    return (
                                      <Card>
                                        <Card.Header>
                                          <ContextAwareToggle
                                            eventKey={subKey.toString()}
                                            classText="level3"
                                            classActive={handleMenuActiveMobileLevel3(
                                              sub.nicename
                                            )}
                                            canToggle={
                                              sub.submenu.length > 0
                                                ? true
                                                : false
                                            }
                                            nicename={sub.nicename}
                                          >
                                            {sub.name}
                                          </ContextAwareToggle>
                                        </Card.Header>
                                      </Card>
                                    );
                                  })}
                                </Accordion>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })}
                      </Accordion>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
              <Card>
                <Card.Header className="lang-menu">
                  <button
                    type="button"
                    onClick={() => handleChangeLang("en")}
                    className={`text-menu ${
                      LocalstorageService.get("lang") === "en"
                        ? "lang-active"
                        : ""
                    }`}
                  >
                    English
                  </button>
                </Card.Header>
              </Card>
              <Card>
                <Card.Header className="lang-menu">
                  <button
                    type="button"
                    onClick={() => handleChangeLang("id")}
                    className={`text-menu ${
                      LocalstorageService.get("lang") === "id"
                        ? "lang-active"
                        : ""
                    }`}
                  >
                    Indonesia
                  </button>
                </Card.Header>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>

      <div className="page-container header-desktop">
        <img
          src="/assets/images/logo-footer.png"
          className="logo"
          alt="logo"
          onClick={() => changePage("/")}
        />
        <div className="nav" style={{ position: "relative" }}>
          <div className="menu">
            {props.menu.map((item, key) => (
              <div key={key} className={`text ${handleMenuActive(item)}`}>
                <span
                  onClick={
                    item.active
                      ? () =>
                          changePage(
                            `/${setPathName(item.nicename.toLowerCase())}`
                          )
                      : () => {}
                  }
                >
                  {item.name}
                </span>

                {item.submenu.length > 0 && (
                  <div className="sub-menu">
                    {item.submenu.map((submenu, keySubmenu) => (
                      <div key={keySubmenu} className="text-submenu">
                        <span
                          onClick={
                            submenu.active
                              ? () =>
                                  changePage(
                                    `/${setPathName(
                                      submenu.nicename.toLowerCase()
                                    )}`
                                  )
                              : () => {}
                          }
                        >
                          {submenu.name}
                        </span>
                        {submenu.submenu.length > 0 && (
                          <div
                            className={`sub ${
                              key === props.menu.length - 1 ? "right" : ""
                            }`}
                          >
                            {submenu.submenu.map((sub, keySub) => (
                              <div key={keySubmenu} className="text-submenu">
                                <span
                                  onClick={
                                    sub.active
                                      ? () =>
                                          changePage(
                                            `/${setPathName(
                                              sub.nicename.toLowerCase()
                                            )}`
                                          )
                                      : () => {}
                                  }
                                >
                                  {sub.name}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="action">
            <span
              className="text"
              style={{ width: "21px", objectFit: "contain" }}
              onClick={() => setShowSearch(!showSearch)}
            >
              <img src="/assets/images/icon-luv.png" alt="" />
            </span>
            <span
              className={`text ${
                LocalstorageService.get("lang") === "en" ? "active" : ""
              }`}
              onClick={() => handleChangeLang("en")}
            >
              EN
            </span>
            <span
              className={`text ${
                LocalstorageService.get("lang") === "id" ? "active" : ""
              }`}
              onClick={() => handleChangeLang("id")}
            >
              ID
            </span>
          </div>

          {showSearch && (
            <div
              style={{ position: "absolute", bottom: "-40px", right: "0px" }}
            >
              <input
                type="text"
                placeholder="Search..."
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    searchPage("/search?text=" + e.currentTarget.value);
                }}
                style={{
                  padding: "4px 16px",
                  width: "360px",
                  borderRadius: "6px",
                  zIndex: "9",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CompHeader);
