import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

//styles
import "./format-11.style.scss";

//React Bootstrap
import { Container, Row, Col, Tabs, Tab, Modal, Button } from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import Modal1 from "../../modal/modal-1/modal-1.component";
import { useMediaQuery } from "react-responsive";

const ModalDetail = (props) => {
  return (
    <Modal
      className='modal-profile'
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Modal1 item={props.data} />
      </Modal.Body>
    </Modal>
  );
};

const Format11 = (props) => {
  const [keyTab, setKeyTab] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setKeyTab(props.item.data.selected);
  }, [props.item.data.selected]);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const handleShowModal = (val, modalData) => {
    setModalShow(val);
    setModalData(modalData);
  };

  const openPdf = (link) => {
    if (link && link !== null && link !== "") window.open(link, "_blank");
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix='container-xl format-11' key={props.keyUniq}>
      <ModalDetail
        backdrop={"static"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalData}
      />

      <div className='content'>
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className='title'>
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className='desc'>{parse(props.item.desc)}</Row>
        )}

        {/* DATA */}
        {props.item.data &&
          props.item.data.tab &&
          props.item.data.tab.length > 0 && (
            <Tabs
              activeKey={keyTab}
              onSelect={(k) => {
                setKeyTab(k);
              }}
              className='justify-content-center tab-menu'>
              {props.item.data.tab.map((tab, uniq) => {
                if (props.item.data.selected == tab.id) {
                  return (
                    <Tab eventKey={tab.id} title={tab.name}>
                      <div className='content-data'>
                        {tab.data.result.map((item, uniq) => {
                          return (
                            <div>
                              <div className='grid-data'>
                                <img src={isTabletOrMobileDevice && checkValue(item.image_mobile) ? item.image_mobile : item.image} alt='' />
                                {!isTabletOrMobileDevice && (
                                  <div className='grid-desc'>
                                    <label className='title'>{item.name}</label>
                                    <label className='subtitle'>
                                      {item.subname}
                                    </label>
                                    <ButtonFillGreen
                                      text='View Profile'
                                      onClick={() => handleShowModal(true, item)}
                                    />
                                  </div>
                                )}
                                {isTabletOrMobileDevice && (
                                  <div
                                    style={{
                                      flex: 1,
                                    }}>
                                    <div className='grid-desc'>
                                      <label className='title'>{item.name}</label>
                                      <label className='subtitle'>
                                        {item.subname}
                                      </label>
                                      <ButtonFillGreen
                                        text='View Profile'
                                        onClick={() =>
                                          handleShowModal(true, item)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className='content-download'>
                        <ButtonOutlineGreen
                          text={tab.text_download}
                          onClick={() => openPdf(tab.link_download)}
                        />
                      </div>
                    </Tab>
                  );
                }
              })}
            </Tabs>
          )}
      </div>

      {/* IMAGE */}
      {checkValue(props.item.image) && (
        <Row className='justify-content-center'>
          <img src={checkImageMobile()} className='img-fluid' alt='' />
        </Row>
      )}
    </Container>
  );
};

export default Format11;
