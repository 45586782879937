import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import moment from "moment";

//services
import Utils from "../../../utils/utils";
import LocalstorageService from "../../../services/localstorage.service";

//styles
import "./format-16.style.scss";

//React Bootstrap
import { Container, Row, DropdownButton, Dropdown } from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import collectionsServices from "../../../services/collections.services";
import CompFilter from "../../filter/filter.component";

const Format16 = (props) => {
  let history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [payLoadFilter, setPayloadFilter] = useState(null);
  
  useEffect(() => {
    if (props?.item?.data) {
      setData(props.item);
    }
  }, [props.item]);
  

  const getContentPagePagination = async (isFilter=false, payload=null) => {
    setPayloadFilter(payload);

    setLoading(true);
    let response = await collectionsServices.getContentPagePagination(
      data.id,
      isFilter ? 1 : data.dataNextPage,
      payload
    );

    let _data = {...data};
    let res = { ...response.data };
    _data.dataCurrentPage = res.dataCurrentPage;
    _data.dataLastPage = res.dataLastPage;
    _data.dataNextPage = res.dataNextPage;
    _data.dataTotal = res.dataTotal;
    _data.hasMorePages = res.hasMorePages;

    if(isFilter){
      _data.data = [...res.data];
    }else{
      _data.data = [..._data.data, ...res.data];
    }
   

    setData(_data);
    setLoading(false);
  };

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const getTitleUrl = (str) => {
    return str.replace(/[^\w\s]/gi, '').split(" ").join("-");
  }

  const changePage = (title, id) => {
    history.push("detail/" + getTitleUrl(title) + "?id=" + id);
  };


  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if (isTabletOrMobileDevice && checkValue(props.item.image_mobile)) {
      return props.item.image_mobile;
    } else {
      return props.item.image;
    }
  };

  return (
    <Container bsPrefix="container-xl format-16" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}

        {/* FILTER */}
        <CompFilter onFilter={getContentPagePagination} idContent={props.idContent} subname={props.item.subname} />

        {/* DATA */}
        {data?.data && data?.data?.length > 0 && (
          <div className="content-data">
            {data?.data?.map((item) => {
              return (
                <div>
                  <div className="grid-data">
                    {!isTabletOrMobileDevice && (
                      <div className="badge">
                         <span className="label-date">
                          {item?.date?.split("-")[2]}
                        </span>
                        <span className="label-month">
                          { item?.date ? Utils.getMonth(item?.date?.split("-")[1]) : ''}
                        </span>
                        <span className="label-year">
                          {item?.date?.split("-")[0]}
                        </span>
                      </div>
                    )}
                    <img
                      src={
                        isTabletOrMobileDevice && checkValue(item.image_mobile)
                          ? item.image_mobile
                          : item.image
                      }
                      alt=""
                    />
                    {!isTabletOrMobileDevice && (
                      <div className="grid-desc">
                        <div className="cont">
                          <label className="title">{item?.name ? item.name : ''}</label>
                          <p className="short-desc">
                            {item?.short_desc ? parse(item.short_desc) : ''}
                          </p>
                        </div>
                        <ButtonFillGreen
                          text={Utils.getLabel("read_more")}
                          onClick={() => changePage(item.name, item.id)}
                        />
                        {/* <ButtonFillGreen text="Read More" onClick={() => {}} /> */}
                      </div>
                    )}
                    {isTabletOrMobileDevice && (
                      <div className="grid-desc">
                        <div className="cont">
                          <label className="title">{item?.name ? item.name : ''}</label>
                          <p className="short-desc">
                            {item?.short_desc === null || item?.short_desc === ""
                              ? ""
                              : parse(item?.short_desc)}
                          </p>
                        </div>
                        <ButtonFillGreen
                          text={Utils.getLabel("read_more")}
                          onClick={() => changePage(item.id)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {
        data?.is_pagination == 1 && data?.hasMorePages &&
        <div className="action">
        <ButtonOutlineGreen text={loading ? 'Loading' : Utils.getLabel("load_more")} onClick={loading ? () => {} : () => getContentPagePagination(false, payLoadFilter)} />
      </div>
      }
    </Container>
  );
};

export default Format16;

// desc: null
// format: 16
// id: 59
// images_id: null
// images_mobile_id: null
// is_data: 1
// is_order: 0
// name: "coba"
// short_desc: null
// subname: null
// uniqueId: "f7cdadb9-c7ee-4634-a134-fd72c607120b"
