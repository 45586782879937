import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-6.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { ButtonFill } from "../../button/button.component";

const Format6 = (props) => {
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container
      bsPrefix="container-xl format-6"
      style={{ backgroundImage: `url('${checkImageMobile()}')` }}
      key={props.keyUniq}
      onClick={() => props.handleShowModalVideo(true, props.item.link_video)}
    >
      <img src="assets/images/icon-play.png" className="img-icon" alt="" />
    </Container>
  );
};

export default Format6;
