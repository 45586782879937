import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-form-email.style.scss";

//React Bootstrap
import { Container, Row, Col, Form } from "react-bootstrap";
import { ButtonFillGreen } from "../../button/button.component";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//services
import CollectionsServices from "../../../services/collections.services";
import LocalstorageService from "../../../services/localstorage.service";


const FormatFormEmail = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [listForm, setListForm] = useState([]);
  
  useEffect(() => {
    let i =0;
    let _form = [...props.item.body];
    while(i<_form.length){
      _form[i].value = "";
      i++;
    }

    setListForm(_form)
  }, [])

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if (isTabletOrMobileDevice && checkValue(props.item.image_mobile)) {
      return props.item.image_mobile;
    } else {
      return props.item.image;
    }
  };

  const renderFormField = (item) => {
    switch (item.type) {
      case 0:
        return (
          <Form.Group bsPrefix="form-group-tap" as={Col} sm="12" md="6" lg="6">
            <Form.Control type="text" placeholder={item.placeholder}  onChange={e => handleOnChangeValueText(e.target.value, item.id)}/>
          </Form.Group>
        );

      case 3:
        return (
          <Form.Group
            bsPrefix="form-group-tap"
            as={Col}
            sm="12"
            md="12"
            lg="12"
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder={item.placeholder}
              onChange={e => handleOnChangeValueText(e.target.value, item.id)}
            />
          </Form.Group>
        );
    }
  };

  const handleOnChangeValueText = (val, id) => {
    let _listForm = {...listForm};
    let sForm = listForm.find((item) => item.id === id)
    if(sForm){
      sForm.value = val;
      _listForm = listForm;
    }

    setListForm(_listForm);
  }

  const handleOnSubmit = async() => {
    setSubmitLoading(true);
    let valid = true;

    let formSubmit = {};
    let i=0;
    while(i<listForm.length){
      formSubmit[listForm[i].nicename] = listForm[i].value ? listForm[i].value : "";
      if(listForm[i].is_required === 1 && listForm[i].value === ""){
        valid = false;
        break;
      }
      i++;
    }


    let msgSukses = "";
    let msgError = "";
    let msgRequire = "";
    if (LocalstorageService.get("lang") === "en") {
      msgSukses = "Message sent successfully";
      msgError = "An error occurred in the system";
      msgRequire = "Please fill in all fields"
    } else {
      msgSukses = "Pesan berhasil dikirim";
      msgError = "Terjadi kesalahan dalam sistem";
      msgRequire = "Semua kolom harus diisi";
    }

    if(valid){
      let response = await CollectionsServices.postContactus(formSubmit);
      if(response.success){
        toast.success(msgSukses, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else{
        toast.error(msgError, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }

      setSubmitLoading(false);
    }
    else{
      toast.error(msgRequire, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        setSubmitLoading(false);
    }
  }

  return (
    <Container bsPrefix="container-xl format-form-email" key={props.keyUniq}>
       <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      />

      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.title) && (
          <Row className="title">
            <h1>{props.item.title}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.subtitle1) && (
          <Row className="desc"><p>{props.item.subtitle1}</p></Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.subtitle2) && (
          <Row className="desc subtitle2"><p>{props.item.subtitle2}</p></Row>
        )}

        {/* FORM */}

        <Form.Row className="form-email">
          {listForm.map((item) => {
            return renderFormField(item);
          })}
        </Form.Row>
        <div>
          <ButtonFillGreen text={submitLoading ? "Loading..." : props.item.submit} onClick={submitLoading ? () => {} : () => handleOnSubmit()} />
        </div>
      </div>

      {/* IMAGE */}
      {checkValue(props.item.image) && (
        <Row className="img-bg justify-content-center">
          <img src={checkImageMobile()} alt="" />
        </Row>
      )}
    </Container>
  );
};

export default FormatFormEmail;
