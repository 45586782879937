import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import moment from "moment";

//services
import Utils from "../../../utils/utils";
import LocalstorageService from "../../../services/localstorage.service";

//styles
import "./format-news-update.style.scss";

//React Bootstrap
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";

const FormatNewsUpdate = (props) => {
  let history = useHistory();


  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const getTitleUrl = (str) => {
    return str.replace(/[^\w\s]/gi, '').split(" ").join("-");
  }

  const reloadPage = (item) => {
    window.location.href = `${window.location.origin}/detail/${getTitleUrl(item.name)}?id=${item.id}`;
  }

  const changePage = (id) => {
    history.push("detail/" + id);
  };

  const setTitlelist = () => {
    if (LocalstorageService.get("lang") === "en") {
      return props.item.subname + " List";
    } else {
      return "Daftar " + props.item.subname;
    }
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });


  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-news-update" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}

        {/* DATA */}
        {props.item.data && props.item.data.length > 0 && (
          <div className="content-data">
            {props.item.data.map((item) => {
              return (
                <div>
                  <div className="grid-data">
                    {!isTabletOrMobileDevice && (
                      <div className="badge">
                        <span className="label-date">
                          {item?.date?.split("-")[2]}
                        </span>
                        <span className="label-month">
                          { item?.date ? Utils.getMonth(item?.date?.split("-")[1]) : ''}
                        </span>
                        <span className="label-year">
                          {item?.date?.split("-")[0]}
                        </span>
                      </div>
                    )}
                    <img src={isTabletOrMobileDevice && checkValue(item.image_mobile) ? item.image_mobile : item.image} alt="" />
                    {!isTabletOrMobileDevice && (
                      <div className="grid-desc">
                        <div className="cont">
                          <label className="title">{item?.name ? item?.name : ''}</label>
                          <p className="short-desc">
                            {item?.short_desc ? parse(item.short_desc) : ''}
                          </p>
                        </div>
                        <ButtonFillGreen
                          text={Utils.getLabel("read_more")}
                          onClick={() => reloadPage(item)}
                        />
                        {/* <ButtonFillGreen text="Read More" onClick={() => {}} /> */}
                      </div>
                    )}
                    {isTabletOrMobileDevice && (
                    <div className="grid-desc">
                      <div className="cont">
                        <label className="title">{item?.name ? item.name : ''}</label>
                        <p className="short-desc">{item?.short_desc === null || item?.short_desc === "" ? "" : parse(item?.short_desc)}</p>
                      </div>
                      <ButtonFillGreen
                         text={Utils.getLabel("read_more")}
                        onClick={() => reloadPage(item)}
                      />
                    </div>
                  )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Container>
  );
};

export default FormatNewsUpdate;

// desc: null
// format: 16
// id: 59
// images_id: null
// images_mobile_id: null
// is_data: 1
// is_order: 0
// name: "coba"
// short_desc: null
// subname: null
// uniqueId: "f7cdadb9-c7ee-4634-a134-fd72c607120b"
