import React, { useEffect, useState } from "react";
import collectionsServices from "../../services/collections.services";
import localstorageService from "../../services/localstorage.service";
import { Container, Row, DropdownButton, Dropdown } from "react-bootstrap";
import "./filter.style.scss";

const CompFilter = ({
  year = true,
  month = true,
  order = true,
  idContent,
  subname = null,
  onFilter = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");

  const [label, setLabel] = useState(null);

  const setTitlelist = () => {
    if (localstorageService.get("lang") === "en") {
      setLabel({
        subname: subname + " List",
        year: "Year",
        month: "Bulan",
        order: "Sort",
      });
    } else {
      setLabel({
        subname: "Daftar " + subname,
        year: "Tahun",
        month: "Bulan",
        order: "Sortir",
      });
    }
  };

  useEffect(() => {
    getFilter();
    setTitlelist();
  }, []);

  const handleSelectOption = (type, value) => {
    let payload = {};

    if(year)
      payload.year = selectedYear;

    if(month)
      payload.month = selectedMonth;

    if(order)
      payload.sort = selectedOrder;
 
    switch (type) {
      case "year":
        if(selectedYear === value)
          return;

        setSelectedYear(value);
        payload.year = value;
        break;

      case "month":
        if(selectedMonth === value)
          return;

        setSelectedMonth(value);
        payload.month = value;
        break;

      case "order":
        if(selectedOrder === value)
          return;

        setSelectedOrder(value);
        payload.sort = value;
        break;
    }

    onFilter(true, payload);
  };

  const getFilter = async () => {
    let response = await collectionsServices.getFilter(idContent);
    if (response.success) {
      setSelectedYear(response.data.year[0]);
      setSelectedMonth(response.data.month[0]);
      setSelectedOrder(response.data.order[0]);
      setFilter(response.data);
      setLoading(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <div className="filter">
      {
        subname && <span className="title">{label?.subname}</span>
      }
      <div className="action-select">
        {year && (
          <DropdownButton
            id="dropdown-basic-button"
            title={`${label?.year} - ${selectedYear}`}
          >
            {filter?.year.map((year, key) => (
              <Dropdown.Item
                key={key}
                onClick={() => handleSelectOption("year", year)}
              >
                {year}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        {month && (
          <DropdownButton
            id="dropdown-basic-button"
            title={`${label?.month} - ${selectedMonth}`}
          >
            {filter?.month.map((month, key) => (
              <Dropdown.Item
                key={key}
                onClick={() => handleSelectOption("month", month)}
              >
                {month}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        {order && (
          <DropdownButton
            id="dropdown-basic-button"
            title={`${label?.order} - ${selectedOrder}`}
          >
            {filter?.order.map((order, key) => (
              <Dropdown.Item
                key={key}
                onClick={() => handleSelectOption("order", order)}
              >
                {order}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </div>
    </div>
  );
};

export default CompFilter;
