// setter
const set = (key, data) => {
  localStorage.setItem(key, data);
  window.location.reload();
};

// getter
const get = (key) => {
  return localStorage.getItem(key);
};

// remove
const remove = (key) => {
  localStorage.removeItem(key);
};

// remove all
const clear = () => {
  localStorage.clear();
};

export default {
  set,
  get,
  remove,
  clear,
};
