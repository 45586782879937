import React from "react";
import parse from "html-react-parser";

//styles
import "./format-4.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { ButtonFill, ButtonFillGreen } from "../../button/button.component";
import { useMediaQuery } from "react-responsive";

const checkValue = (val) => {
  return val && val !== "" ? true : false;
};

const Format4 = (props) => {
  const handleGoToLink = (link) => {
    if (link && link !== null && link !== "") window.location.replace(link);
  };
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-4" key={props.keyUniq}>
      {checkValue(props.item.image) && (
        <img src={checkImageMobile()} className="img-fluid" alt="" />
      )}
      <div className="content">
        {/* IMAGE */}

        {/* TITLE */}

        <Row className="title">
          <h1>{props.item.name}</h1>
        </Row>

        {/* SUBTITLE */}
        {checkValue(props.item.subname) && (
          <Row className="subtitle">
            <span>{parse(props.item.subname)}</span>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}
        {isTabletOrMobileDevice ? (
          <ButtonFillGreen
            text={props.item.text_button}
            onClick={() => handleGoToLink(props.item.link_video)}
          />
        ) : (
          <div>
            <ButtonFill
              text={props.item.text_button}
              onClick={() => handleGoToLink(props.item.link_video)}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Format4;
