import Format1 from "./format-1/format-1.component";
import Format2 from "./format-2/format-2.component";
import Format3 from "./format-3/format-3.component";
import Format4 from "./format-4/format-4.component";
import Format5 from "./format-5/format-5.component";
import Format6 from "./format-6/format-6.component";
import Format7 from "./format-7/format-7.component";
import Format8 from "./format-8/format-8.component";
import Format9 from "./format-9/format-9.component";
import Format10 from "./format-10/format-10.component";
import Format11 from "./format-11/format-11.component";
import Format12 from "./format-12/format-12.component";
import Format13 from "./format-13/format-13.component";
import Format14 from "./format-14/format-14.component";
import Format15 from "./format-15/format-15.component";
import Format16 from "./format-16/format-16.component";
import Format17 from "./format-17/format-17.component";
import FormatNewsUpdate from "./format-news-update/format-news-update.component";
import FormatFormEmail from "./format-form-email/format-form-email.component";

export default {
    Format1,
    Format2,
    Format3,
    Format4,
    Format5,
    Format6,
    Format7,
    Format8,
    Format9,
    Format10,
    Format11,
    Format12,
    Format13,
    Format14,
    Format15,
    Format16,
    Format17,
    FormatNewsUpdate,
    FormatFormEmail
}