import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-9.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

const Format9 = (props) => {
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix='container-xl format-9' key={props.keyUniq}>
      <Container bsPrefix='container-xl container-format-9'>
        {/* IMAGE */}
        {checkValue(props.item.image) && (
          <Row className='box-img-photo'>
            <img src={checkImageMobile()} className='img-fluid photo' style={{objectFit: "contain"}} alt='' />
          </Row>
        )}
        <div className='content'>
          {/* TITLE */}
          {checkValue(props.item.name) && (
            <Row className='title'>
              <h1>{props.item.name}</h1>
            </Row>
          )}

          {/* Subtitle */}
          {checkValue(props.item.subname) && (
            <Row className='subtitle'>
              <h5>{props.item.subname}</h5>
            </Row>
          )}

          <Row className='divider'>
            <Col>
              <hr />
            </Col>
          </Row>

          {/* DESCRIPTION */}
          {checkValue(props.item.desc) && (
            <Row className='desc'>{parse(props.item.desc)}</Row>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default Format9;
