import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

import CollectionsServices from "../../services/collections.services";

import Loader from "react-loader-spinner";

//styles
import "./grievance-table.style.scss";

const tapTableStyles = {
  rows: {
    style: {
      minHeight: "50px", // override the row height
    },
  },
  headRow: {
    style: {
      border: "solid",
      borderWidth: "1px",
      borderColor: "#B3B3B3",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#1BB14A",
      color: "#ffffff",
      fontFamily: "SansProRegular",
      fontSize: "1rem",
      border: "solid",
      borderWidth: "1px",
      borderColor: "#B3B3B3",
    },
    activeSortStyle: {
      color: "#ffffff",
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: "#ffffff",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "#ffffff",
      },
      "&:hover": {
        color: "#ffffff",
      },
    },
  },
  cells: {
    style: {
      backgroundColor: "#ffffff",
      color: "#000000",
      fontFamily: "SansProRegular",
      fontSize: "1rem",
      border: "solid",
      borderWidth: "1px",
      borderColor: "#B3B3B3",
      whiteSpace: "pre-wrap !important"
    },
  },
};

const PageGrievanceTable = (props) => {
  const [dataTableKeluhan, setDataTableKeluhan] = useState([]);
  const [columnsTableKeluhan, setColumnsTableKeluhan] = useState([]);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const handleOnClickDetail = (row) => {
    if (row.is_download !== 0) {
      if (
        row.file_download &&
        row.file_download !== null &&
        row.file_download !== ""
      )
        window.open(row.file_download, "_blank");
    }
  };

  useEffect(() => {
    getTableKeluhan();
  }, []);

  const getTableKeluhan = async () => {
    let response = await CollectionsServices.getTableKeluhan();

    //"{\"success\":true,\"data\":{\"list_data\":[],\"column\":[{\"label\":\"To whom it may concern.\",\"value\":\"keluhan_kepada\",\"type_name\":\"Text\"},{\"label\":\"Date Filled\",\"value\":\"date_filed\",\"type_name\":\"Text\"},{\"label\":\"Date Closed\",\"value\":\"date_closed\",\"type_name\":\"Text\"},{\"label\":\"Status\",\"value\":\"status_complain\",\"type_name\":\"Text\"}]},\"message\":\"Data successfully.\"}"

    let _col = [];
    let i = 0;
    while (i < response.data.column.length) {
      if (response.data.column[i].value === "keluhan_kepada") {
        let col = {
          name: response.data.column[i].label,
          selector: response.data.column[i].value,
          sortable: true,
          width: "25%",
          cell: (row) => (
            <span>
              {row.keluhan_kepada}
            </span>
          ),
        };

        _col.push(col);
      } else {
        let col = {
          name: response.data.column[i].label,
          selector: response.data.column[i].value,
          sortable: true,
        };

        _col.push(col);
      }

      i++;
    }

    _col.push({
      name: "Detail",
      selector: "detail",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <span
          style={{ cursor: "pointer", color: "#0A5A23" }}
          onClick={() => handleOnClickDetail(row)}
        >
          {row.is_download === 0 ? "" : "Click Here"}
        </span>
      ),
    });

    setColumnsTableKeluhan(_col);
    setDataTableKeluhan(response.data.list_data);
  };

  if (columnsTableKeluhan.length === 0) {
    return (
      <div className="box-loader-content">
        <Loader type="TailSpin" color="#81C42A" height={100} width={100} />
      </div>
    );
  }

  return (
    <Container bsPrefix="container-xl grievance-table">
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}
      </div>

      {/* TABLE */}
      <Row className="justify-content-end">
        <DataTable
          columns={columnsTableKeluhan}
          data={dataTableKeluhan}
          defaultSortField="title"
          customStyles={tapTableStyles}
          noHeader
          pagination
        />
      </Row>
    </Container>
  );
};

export default PageGrievanceTable;
