import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//styles
import "./format-5.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { ButtonFill } from "../../button/button.component";

const Format5 = (props) => {

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const handleGoToLink = (link) => {
    if(link && link !== null && link !== "")
    window.location.href = link;
  }

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });
  
  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  return (
    <Container bsPrefix="container-xl format-5"  key={props.keyUniq}>
      {/* IMAGE */}
      {props.item.image && props.item.image !== "" && (
        <Row className="justify-content-center box-img-rect">
          <img src={checkImageMobile()} className="img-fluid img-rectangle" alt="" />
          <div className="content">
            {/* TITLE */}
            <div className="title">
              <h1>{props.item.name}</h1>
            </div>

            <div className="action">
            <ButtonFill text={props.item.text_button} onClick={() => handleGoToLink(props.item.link_video)}/>
            </div>
            
          </div>
        </Row>
      )}

    </Container>
  );
};

export default Format5;
