import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";

import Loader from "react-loader-spinner";

//services
import CollectionsServices from "../../services/collections.services";

//utils
import Utils from "../../utils/utils";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

//styles
import "./detail.style.scss";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { useLocation } from 'react-router-dom';


const PageDetail = (props) => {
  const history = useHistory();

  const location = useLocation();

  const slug = qs.parse(location.search);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const [dataRelated, setDataRelated] = useState(null);
  const [dataPage, setDataPage] = useState({
    name: "",
    date: "",
    image: "",
    desc: "",
  });
  const [loading, setLoading] = useState(true);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  useEffect(() => {
    getData();
  }, []);


  const getData = async (id=null) => {
    getContentDetail(id);
    getContentRelated(id);
  };

  const getTitleUrl = (str) => {
    return str.replace(/[^\w\s]/gi, '').split(" ").join("-");
  }

  const reloadPage = (item) => {
    window.location.href = `${window.location.origin}/detail/${getTitleUrl(item.name)}?id=${item.id}`;
  }

  const getContentDetail = async (id=null) => {
    setLoading(true);
    let payload = {
      id: id === null ? slug.id : id,
    };
    let response = await CollectionsServices.getContentDetail(
      slug.id + ".json",
      payload
    );

    setLoading(false);
    //debugger;
    setDataPage(response.data.result);
  };

  const getContentRelated = async (id) => {
    let payload = {
      id: id === null ? slug.id : id,
    };
    let response = await CollectionsServices.getContentDetailRelated(
      slug.id + ".json",
      payload
    );

    setDataRelated(response.data);
  };

  if (loading) {
    return (
      <div className="box-loader-content">
        <Loader type="TailSpin" color="#81C42A" height={100} width={100} />
      </div>
    );
  }

  const setDate = (date) => {
    return `${date?.split("-")[1]} ${Utils.getMonth(date?.split("-")[1])} ${
      date?.split("-")[0]
    }`;
  };

  return (
    <Container bsPrefix="container-xl detail-page">
      <Row>
        <Col className="detail-page--content" sm="12" md="9" lg="9">
          <div className="box-info">
            <span className="title">{dataPage?.name ? dataPage?.name : ''}</span>
            <span className="date">{dataPage?.date ? setDate(dataPage?.date) : ''}</span>
          </div>{
            dataPage?.image &&  <img className="img-news" src={dataPage?.image} alt="" />
          }
         
          <div className="box-desc">
            {dataPage?.desc && dataPage.desc !== ""
              ? parse(dataPage?.desc)
              : ""}
          </div>
          <div className="box-nav">
            <div className="prev">
              {dataRelated?.dataPrev && (
                <React.Fragment>
                  <span className="nav" onClick={() => reloadPage(dataRelated?.dataPrev)}>Previous News</span>
                  <span className="name" onClick={() => reloadPage(dataRelated?.dataPrev)}>{dataRelated?.dataPrev.name}</span>
                </React.Fragment>
              )}
            </div>

            {isTabletOrMobileDevice && <div className="divider"></div>}

            <div className="next">
              {dataRelated?.dataNext && (
                <React.Fragment>
                  <span className="nav" onClick={() => reloadPage(dataRelated?.dataNext)}>Next News</span>
                  <span className="name" onClick={() => reloadPage(dataRelated?.dataNext)}>{dataRelated?.dataNext.name}</span>
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
        {!isTabletOrMobileDevice && (
          <Col className="detail-page--side" sm="12" md="3" lg="3">
            {dataRelated?.dataRecent?.length > 0 && (
              <div className="recent-news">
                {/* {dataPage?.recent_news.map((item) => (
              <span className="list-item">{item.name}</span>
            ))} */}
                <span className="title-side-news">Recent News</span>

                {dataRelated?.dataRecent?.map((item, key) => (
                  <span className="list-item" key={key} onClick={() => reloadPage(item)}>
                    {item.name}
                  </span>
                ))}
              </div>
            )}

            {dataRelated?.dataRelated?.length > 0 && (
              <div className="recent-news">
                {/* {dataPage?.recent_news.map((item) => (
              <span className="list-item">{item.name}</span>
            ))} */}
                <span className="title-side-news">Related News</span>

                {dataRelated?.dataRelated?.map((item, key) => (
                  <span className="list-item" key={key}  onClick={() => reloadPage(item)}>
                    {item.name}
                  </span>
                ))}
              </div>
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default PageDetail;
