import React from "react";
import parse from "html-react-parser";

//styles
import "./modal-1.style.scss";

//React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

const Modal1 = (props) => {
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  return (
    <Container bsPrefix='container-xl modal-1' key={props.keyUniq}>
      {/* IMAGE */}
      {checkValue(props.item.image) && (
        <Row className='box-img-photo'>
          <img src={props.item.image} className='img-fluid photo' alt='' />
        </Row>
      )}

      <div className='content'>
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className='title'>
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* Subtitle */}
        {checkValue(props.item.subname) && (
          <Row className='subtitle'>
            <h5>{props.item.subname}</h5>
          </Row>
        )}

        <Row className='divider'>
          <Col>
            <hr />
          </Col>
        </Row>

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className='desc'>{parse(props.item.desc)}</Row>
        )}
      </div>
    </Container>
  );
};

export default Modal1;
