import React, { useEffect, useState } from "react";
import "./search.style.scss";
import { ButtonFillGreen } from "../../components/button/button.component";
import utils from "../../utils/utils";
import Loader from "react-loader-spinner";
import collectionsServices from "../../services/collections.services";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import parse from "html-react-parser";

const PageSearch = () => {
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [doSearch, setDoSearch] = useState(false);

  const [data, setData] = useState([]);
  const location = useLocation();

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  useEffect(() => {
    let param = qs.parse(location.search);
    if (!isObjectEmpty(param)) {
      setKeyword(param.text);
      getDataSearchFirst(param.text);
    }
  }, []);

  const dummyDataSearch = [
    {
      title: "About Us",
      url: "/about-tap",
      desc: "PT Triputra Agro Persada Tbk (TAPG) is a group of companies engaged in the palm oil plantation and palm oil processing industry, as well as rubber plantation di Jambi, East Kalimantan, and East Kalimantan",
    },
    {
      title: "Lorem Ipsum",
      url: "/#",
      desc: "Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      title: "Lorem Ipsum",
      url: "/#",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      title: "Lorem Ipsum",
      url: "/#",
      desc: "Lorem ipsum dolor sit amet, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ];

  const getDataSearchFirst = async (word) => {
    setDoSearch(true);
    setLoading(true);
    let payload = {
      keyword: word,
    };

    try {
      let response = await collectionsServices.getSearchKeyword(payload);

      setLoading(false);
      setData(response.result);
    } catch (error) {

      setLoading(false);
      setData([]);
    }
  };

  const getDataSearch = async () => {
    setDoSearch(true);
    setLoading(true);
    let payload = {
      keyword: keyword,
    };


    try {
      let response = await collectionsServices.getSearchKeyword(payload);

      setLoading(false);
      setData(response.result);
    } catch (error) {
      
      setLoading(false);
      setData([]);
    }
   
  };

  const onChangeInput = (e) => {
    setKeyword(e.currentTarget.value);
  };

  return (
    <div className="page-search">
      <h1>{utils.getLabel("search")}</h1>
      <div className="input-search">
        <img className="ic-luv" src="/assets/images/icon-luv.png" alt="" />
        <input
          type="text"
          value={keyword || ""}
          onChange={onChangeInput}
          onKeyDown={(e) => {
            if (e.key === "Enter") getDataSearch();
          }}
        />
        <div className="btn-search">
          <ButtonFillGreen
            text={utils.getLabel("search")}
            onClick={() => getDataSearch()}
          />
        </div>
      </div>

      {loading && (
        <div className="box-loader-search">
          <Loader type="TailSpin" color="#81C42A" height={40} width={40} />
        </div>
      )}

      {!loading && (
        <div className="box-search">
          {data.length > 0 ? (
            data.map((item, key) => {
              return (
                <div key={key} className="item-search">
                  <a href={item?.link_openpage}>
                    <h2>{item?.name}</h2>
                  </a>
                  <div className="short-desc-search">
                    {item?.short_desc ? parse(item?.short_desc) : item?.desc ? parse(item?.desc) : ""}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {doSearch ? (
                <div style={{ textAlign: "center" }}>
                  {utils.getLabel("result_search")}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageSearch;
