import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import Loader from "react-loader-spinner";
import "./App.scss";

//pages
import PageGenerated from "./pages/generated/generated.page";

//custom components
import CompHeader from "./components/header/header.component";
import CompFooter from "./components/footer/footer.component";

//React Bootstrap
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";

//services
import CollectionsServices from "./services/collections.services";
import PageDetail from "./pages/detail/detail.page";
import LocalstorageService from "./services/localstorage.service";
import Page404 from "./pages/page404/page404.component";
import PageSearch from "./pages/pageSearch/search.page";

//styles
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

const App = () => {
  const [menu, setMenu] = useState(null);
  const [footer, setFooter] = useState(null);

  useEffect(() => {
    getMenu();
    getFooter();
  }, []);

  const getMenu = async () => {
    let response = await CollectionsServices.getMenu();
    setMenu(response.data);
  };

  const getFooter = async () => {
    let response = await CollectionsServices.getFooter();
    setFooter(response.data);
  };

  if (menu === null || footer === null) {
    return <div className="box-loader">
      <Loader type="TailSpin" color="#81C42A" height={100} width={100} />
    </div>;
  }

  const checkMenuActive = (submenu) => {
    if(submenu.length > 0)
      return false;
    else 
      return true;
  }

  const setPathName = (name) => {
    return name.replace(/ /g, "-");
  }

  

  let arrRoute = [];
  menu.map((item) => {
    item.active = checkMenuActive(item.submenu);
    arrRoute.push({ name: setPathName(item.nicename.toLowerCase()), page: item });
    item.submenu.map((submenu) => {
      submenu.active = checkMenuActive(submenu.submenu);
      arrRoute.push({ name: setPathName(submenu.nicename.toLowerCase()), page: submenu });
      submenu.submenu.map((sub) => {
        sub.active = checkMenuActive(sub.submenu);
        arrRoute.push({ name: setPathName(sub.nicename.toLowerCase()), page: sub });
      });
    });
  });

  console.log(arrRoute);

  return (
    <React.Fragment>
      <CompHeader menu={menu} />
      <Switch>
        <Redirect exact from="/" to={
          setPathName(arrRoute.find((item) => item.page.type === 1).name.toLowerCase())
        } />

        {arrRoute.map((item) => {
          return (
            <Route path={`/${item.name}`} exact>
              <PageGenerated page={item.page} />
              {/* <h1>CONTENT</h1> */}
            </Route>
          );
        })}

        <Route path={`/detail/:id`} exact>
          <PageDetail />
        </Route>

        <Route path={`/search`} exact>
          <PageSearch />
        </Route>

        <Route path="/404" exact>
          <Page404 />
        </Route>
        <Redirect to="/404" />
      </Switch>
      <CompFooter footer={footer} />
    </React.Fragment>
  );
};

export default App;
