import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

//services
import Utils from "../../../utils/utils";
import LocalstorageService from "../../../services/localstorage.service";

//styles
import "./format-12.style.scss";

//React Bootstrap
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import { useMediaQuery } from "react-responsive";
import collectionsServices from "../../../services/collections.services";
import CompFilter from "../../filter/filter.component";

const Format12 = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payLoadFilter, setPayloadFilter] = useState(null);
  
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if (isTabletOrMobileDevice && checkValue(props.item.image_mobile)) {
      return props.item.image_mobile;
    } else {
      return props.item.image;
    }
  };

  const getContentPagePagination = async (isFilter=false, payload=null) => {
    setPayloadFilter(payload);

    setLoading(true);
    let response = await collectionsServices.getContentPagePagination(
      data.id,
      isFilter ? 1 : data.dataNextPage,
      payload
    );

    let _data = {...data};
    let res = { ...response.data };
    _data.dataCurrentPage = res.dataCurrentPage;
    _data.dataLastPage = res.dataLastPage;
    _data.dataNextPage = res.dataNextPage;
    _data.dataTotal = res.dataTotal;
    _data.hasMorePages = res.hasMorePages;

    if(isFilter){
      _data.data = [...res.data];
    }else{
      _data.data = [..._data.data, ...res.data];
    }
   

    setData(_data);
    setLoading(false);
  };

  useEffect(() => {
    if (props?.item?.data) {
      setData(props.item);
    }
  }, [props.item]);

  
  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const setTitlelist = () => {
    if (LocalstorageService.get("lang") === "en") {
      return props.item.subname + " List";
    } else {
      return "Daftar " + props.item.subname;
    }
  };

  const openPdf = (link) => {
    if (link && link !== null && link !== "") window.open(link, "_blank");
  };

  return (
    <Container bsPrefix="container-xl format-12" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}

        {/* FILTER */}
        <CompFilter
          month={false}
          onFilter={getContentPagePagination}
          idContent={props.idContent}
          subname={props.item.subname}
        />

        {/* DATA */}
        {data?.data && data?.data?.length > 0 && (
          <div className="content-data">
            {data?.data?.map((item) => {
              return (
                <div>
                  <div
                    className="grid-data"
                    onClick={() => openPdf(item.link_download)}
                  >
                    {!isTabletOrMobileDevice && (
                      <div className="badge">
                        <span className="label-year">
                          {item.date.split("-")[0]}
                        </span>
                        <span className="label-report">{data.subname}</span>
                      </div>
                    )}
                    <img
                      src={
                        isTabletOrMobileDevice && checkValue(item.image_mobile)
                          ? item.image_mobile
                          : item.image
                      }
                      alt=""
                    />
                    {!isTabletOrMobileDevice && (
                      <div className="grid-desc">
                        <label className="title">{item.name}</label>
                      </div>
                    )}
                  </div>
                  {isTabletOrMobileDevice && (
                    <>
                      <div className="grid-desc">
                        <label className="title">{item.name}</label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <ButtonFillGreen
                            text="View Report"
                            onClick={() => {}}
                          />
                          <img
                            src={"/assets/images/icon-download.png"}
                            alt=""
                            style={{ maxHeight: "40px" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {data?.is_pagination == 1 && data?.hasMorePages && (
        <div className="action">
          <ButtonOutlineGreen
            text={loading ? "Loading" : Utils.getLabel("load_more")}
            onClick={loading ? () => {} : () => getContentPagePagination(false, payLoadFilter)}
          />
        </div>
      )}
    </Container>
  );
};

export default Format12;
