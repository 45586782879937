import API from "./http-common";

const dummy = false;

const getMenu = () => {
  return (
    API.http()
      .get(`${dummy ? "/dummy_data/menu.json" : "menu"} `)
      // .get("menu")
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return false;
      })
  );
};

const getFooter = () => {
  return (
    API.http()
      .get(`${dummy ? "/dummy_data/footer.json" : "footer"}`)
      // .get("footer")
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return false;
      })
  );
};

const getContentPage = (pageId, filter=null) => {
  let payload = {
    id : pageId,
    order : "desc"
  };

  if(filter){
    payload = {...payload, ...filter}
  }

  return API.http()
    .post(`${dummy ? "/dummy_data/content/" + pageId +".json" : "content"}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const getContentDetail = (pageId, payload) => {
  return API.http()
    .post(`${dummy ? "/dummy_data/content/" + pageId : "content/detail"}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const getContentDetailRelated = (pageId, payload) => {
  return API.http()
    .post(`${dummy ? "/dummy_data/content/" + pageId : "content/detailrelated"}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const getContentFormKeluhan = () => {
  return (
    API.http()
      .get('/formkeluhan')
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return false;
      })
  );
}

const postFileBukti = (name, file) => {
  var formData = new FormData();
  formData.append(name, file);
  return API.http()
    .post('/uploadbukti', formData, {
      headers: {
          "Content-Type": "multipart/form-data"
      }
    }).then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const postKeluhan = (payload) => {
  return API.http()
    .post('/keluhankami', payload).then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const postContactus = (payload) => {
  return API.http()
    .post('/contactus', payload).then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};



const getTableKeluhan = () => {
  return (
    API.http()
      .get('/keluhan')
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return false;
      })
  );
}

const getContentPagePagination = (pageId, pageNumber, filter=null) => {
  let payload = {
    id : pageId,
    order : "desc",
    page : pageNumber
  };

  if(filter){
    payload = {...payload, ...filter}
  }

  return API.http()
    .post(`content/page`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const getFilter = (pageId) => {
  let payload = {
    id : pageId
  };
  return API.http()
    .post(`content/year`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

const getSearchKeyword = (payload) => {
  return API.http()
    .post(`search`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};




export default {
  getMenu,
  getFooter,
  getContentPage,
  getContentDetail,
  getContentFormKeluhan,
  postFileBukti,
  postKeluhan,
  getTableKeluhan,
  postContactus,
  getContentPagePagination,
  getContentDetailRelated,
  getFilter,
  getSearchKeyword
};
