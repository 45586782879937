import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

//services
import Utils from "../../../utils/utils";
import LocalstorageService from "../../../services/localstorage.service";

//styles
import "./format-14.style.scss";

//React Bootstrap
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../button/button.component";
import collectionsServices from "../../../services/collections.services";
import CompFilter from "../../filter/filter.component";

const Format14 = (props) => {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payLoadFilter, setPayloadFilter] = useState(null);
  

  useEffect(() => {
    if (props?.item?.data) {
      setData(props.item);
    }
  }, [props.item]);

  const getContentPagePagination = async (isFilter=false, payload=null) => {
    setPayloadFilter(payload);

    setLoading(true);
    let response = await collectionsServices.getContentPagePagination(
      data.id,
      isFilter ? 1 : data.dataNextPage,
      payload
    );

    let _data = {...data};
    let res = { ...response.data };
    _data.dataCurrentPage = res.dataCurrentPage;
    _data.dataLastPage = res.dataLastPage;
    _data.dataNextPage = res.dataNextPage;
    _data.dataTotal = res.dataTotal;
    _data.hasMorePages = res.hasMorePages;

    if(isFilter){
      _data.data = [...res.data];
    }else{
      _data.data = [..._data.data, ...res.data];
    }
   

    setData(_data);
    setLoading(false);
  };

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const checkImageMobile = () => {
    if(isTabletOrMobileDevice && checkValue(props.item.image_mobile)){
      return props.item.image_mobile;
    }
    else{
      return props.item.image;
    }
  }

  const setDate = (date) => {
    return `${date.split("-")[2]} ${Utils.getMonth(date.split("-")[1])}  ${
      date.split("-")[0]
    }`;
  };

  const setTitlelist = () => {
    if (LocalstorageService.get("lang") === "en") {
      return props.item.subname + " List";
    } else {
      return "Daftar " + props.item.subname;
    }
  };

  return (
    <Container bsPrefix="container-xl format-14" key={props.keyUniq}>
      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}

        {/* DESCRIPTION */}
        {checkValue(props.item.desc) && (
          <Row className="desc">{parse(props.item.desc)}</Row>
        )}

        {/* FILTER */}
        <CompFilter
          year={false}
          month={false}
          onFilter={getContentPagePagination}
          idContent={props.idContent}
          subname={props.item.subname}
        />

        {/* DATA */}
        {data?.data && data?.data?.length > 0 && (
          <div className="content-data">
            {data?.data?.map((item) => {
              return (
                <div className="grid-data" onClick={() =>
                  props.handleShowModalVideo(true, item.link_video)
                }>
                  <img src={isTabletOrMobileDevice && checkValue(item.image_mobile) ? item.image_mobile : item.image} alt="" />
                  <div className="grid-desc">
                    <label className="date">{setDate(item.date)}</label>
                    <label className="title">{item.name}</label>
                    <div
                      className="box-icon-play"
                    >
                      <img src={"/assets/images/btn-play.png"} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {
        data?.is_pagination == 1 && data?.hasMorePages &&
        <div className="action">
        <ButtonOutlineGreen text={loading ? 'Loading' : Utils.getLabel("load_more")} onClick={loading ? () => {} : () => getContentPagePagination(false, payLoadFilter)} />
      </div>
      }
    </Container>
  );
};

export default Format14;
