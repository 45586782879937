import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import ReactPlayer from "react-player";

import "./video-modal.style.scss";

const VideoModal = (props) => {
  return (
    <Modal
      {...props}
      show={props.show}
      size="xl"
      centered
      dialogClassName="video-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={props.videoUrl}
            width="100%"
            height="100%"
            controls={true}
            playbackRate={1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
