import React, { useEffect, useState } from "react";

import Utils from "../../utils/utils";

import Select from "react-select";
import Loader from "react-loader-spinner";


//React Bootstrap
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  DropdownButton,
} from "react-bootstrap";
import {
  ButtonFillGreen,
  ButtonOutlineGreen,
} from "../../components/button/button.component";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from "react-dom";
import Files from "react-files";

//styles
import "./grievance-form.style.scss";
import { Dropdown } from "bootstrap";
import utils from "../../utils/utils";

//services
import CollectionsServices from "../../services/collections.services";
import LocalstorageService from "../../services/localstorage.service";



const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const PageGrievanceForm = (props) => {

  const [fileName, setFileName] = useState("");
  const [listForm, setListForm] = useState(null);
  const [buktiLoading, setBuktiLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const checkValue = (val) => {
    return val && val !== "" ? true : false;
  };

  const onFilesChange = async (files, nicename) => {
    if(files){
      let msgSukses = "";
      let msgError = "";
      let _fileName = files[0].name;
      if (LocalstorageService.get("lang") === "en") {
        msgSukses = "Proof file uploaded successfully";
        msgError = "Proof file failed to upload";
      } else {
        msgSukses = "File bukti berhasil diunggah";
        msgError = "File bukti gagal diunggah";
      }

      setBuktiLoading(true);
      let response = await CollectionsServices.postFileBukti(nicename, files[0]);
      setBuktiLoading(false);
      if(response){
        toast.success(msgSukses, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

        setFileName(_fileName);
        handleOnChangeValueText(response.data.link, 13);
      }
      else{
        toast.error(msgError, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    }
  };

  const onFilesError = (error, file) => {
    setFileName(listForm?.file)
  };

  useEffect(() => {
    getContentFormKeluhan();
  }, []);

  const getContentFormKeluhan = async () => {
    let response = await CollectionsServices.getContentFormKeluhan();
    setListForm(response.data);
    setFileName(response.data.file)
  };

  const handleOnSubmit = async() => {
    setSubmitLoading(true);
    let valid = true;

    let formSubmit = {};
    let i=0;
    while(i<listForm.body.length){
      formSubmit[listForm.body[i].nicename] = listForm.body[i].value ? listForm.body[i].value : "";
      if(listForm.body[i].is_required === 1 && listForm.body[i].value === ""){
        valid = false;
        break;
      }
      i++;
    }

    if(valid){

      let msgSukses = "";
      let msgError = "";
      if (LocalstorageService.get("lang") === "en") {
        msgSukses = "Proof file uploaded successfully";
        msgError = "An error occurred in the system";
      } else {
        msgSukses = "File bukti berhasil diunggah";
        msgError = "Terjadi kesalahan dalam sistem";
      }

      let response = await CollectionsServices.postKeluhan(formSubmit);
      if(response.success){
        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else{
        toast.error(msgError, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }

      setSubmitLoading(false);
    }
    else{
      toast.error(listForm.msg_required, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        setSubmitLoading(false);
    }
  }

  const handleOnChangeValueText = (val, id) => {
    if(id === 14){
      if(val.toLowerCase() === "yes")
        val = "1";
      else
        val = "0";
    }

    let _listForm = {...listForm};
    let _bodyForm = [..._listForm.body];
    let sForm = _bodyForm.find((item) => item.id === id)
    if(sForm){
      sForm.value = val;
      _listForm.body = _bodyForm;
    }

    setListForm(_listForm);
  }

  const handleOnChangeValueSelect = (val, id) => {
    let _listForm = {...listForm};
    let _bodyForm = [..._listForm.body];
    let sForm = _bodyForm.find((item) => item.id === id)
    if(sForm){
      sForm.value = val.value;
      _listForm.body = _bodyForm;
    }

    setListForm(_listForm);
  }

  const renderFieldForm = (item, key) => {
    switch(item.id){
      case 1:
      case 2:
      case 5:
      case 6:
      case 7:
        return(
          <Form.Group
          key={key}
            bsPrefix="form-group-tap"
            as={Col}
            sm="12"
            md="6"
            lg="6"
            controlId={`input-${key}`}
          >
            <Form.Label>
              {item.name}<span className="require">{item.is_required ? '*' : ''}</span>
            </Form.Label>
            <Form.Control type={item.type === 2 ? "number" : "text"} placeholder={item.name} value={item.value}
            onChange={e => handleOnChangeValueText(e.target.value, item.id)} />
            <Form.Text className="error-text">{item.placeholder}</Form.Text>
          </Form.Group>
        )

      case 3:
        return(
          <Form.Group
            key={key}
            bsPrefix="form-group-tap"
            as={Col}
            sm="12"
            md="6"
            lg="6"
            controlId={`input-${key}`}
          >
            <Form.Label>
              {item.name}<span className="require">{item.is_required ? '*' : ''}</span>
            </Form.Label>

            <Select options={utils.getCountry()} onChange={(value) => handleOnChangeValueSelect(value, item.id)} />

            <Form.Text className="error-text">{item.placeholder}</Form.Text>
          </Form.Group>
        )

      case 13:
        return(
          <Form.Group
          key={item.key}
            bsPrefix="form-group-tap"
            as={Col}
            sm="12"
            md="12"
            lg="12"
            controlId={`input-${key}`}
          >
            <Form.Label>
              {item.name}<span className="require">{item.is_required ? '*' : ''}</span>
            </Form.Label>

            <div className="files">
              <Files
                className="files-dropzone"
                onChange={(val) => onFilesChange(val, item.nicename)}
                onError={onFilesError}
                accepts={["image/*", ".pdf", "audio/*"]}
                multiple
                maxFiles={3}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <ButtonOutlineGreen text={buktiLoading ? "Loading..." : fileName} onClick={() => {}} />
              </Files>
              </div>
          
          </Form.Group>
        )

        case 14:
          return(
            <Form.Group
            key={key}
              bsPrefix="form-group-tap"
              as={Col}
              sm="12"
              md="12"
              lg="12"
              controlId={`input-${key}`}
            >
              <Form.Label>
                {item.name}<span className="require">{item.is_required ? '*' : ''}</span>
              </Form.Label>
             
              <div key={`inline-${key}`}>
              <Form.Check style={{cursor: "pointer"}} inline label={item.additional[0]} value={item.additional[0]} onChange={e => handleOnChangeValueText(e.target.value, item.id)} name={item.nicename} type={'radio'} id={`inline-radio-${item.additional[0]}`} />
              <Form.Check style={{cursor: "pointer"}} inline label={item.additional[1]} value={item.additional[1]} onChange={e => handleOnChangeValueText(e.target.value, item.id)} name={item.nicename} type={'radio'} id={`inline-radio-${item.additional[1]}`} />
            </div>
  
              <Form.Text className="error-text">{item.placeholder}</Form.Text>
            </Form.Group>
          )

      default:
        return( <Form.Group
          key={item.key}
          bsPrefix="form-group-tap"
          as={Col}
          sm="12"
          md="12"
          lg="12"
          controlId={`input-${key}`}
        >
          <Form.Label>
              {item.name}<span className="require">{item.is_required ? '*' : ''}</span>
            </Form.Label>
            <Form.Control  type={item.type === 2 ? "number" : "text"} placeholder={item.name} value={item.value}
            onChange={e => handleOnChangeValueText(e.target.value, item.id)} />
          <Form.Text className="error-text">{item.placeholder}</Form.Text>
        </Form.Group>)
    }

    
  }

  if(listForm === null){
    return (
      <div className="box-loader-content">
        <Loader type="TailSpin" color="#81C42A" height={100} width={100} />
      </div>
    );
  }

  return (
    <Container bsPrefix="container-xl grievance-form">

      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      />

      <div className="content">
        {/* TITLE */}
        {checkValue(props.item.name) && (
          <Row className="title">
            <h1>{props.item.name}</h1>
          </Row>
        )}
      </div>

      <div className="content">
        <Form.Row>
          {
            listForm?.body?.length > 0 && 
            listForm?.body?.map((item, key) => {
              return renderFieldForm(item, key)
            })
          }
        
        </Form.Row>
        <br />

        {
          listForm?.body?.length > 0 &&
          <Form.Row>
          <ButtonFillGreen text={submitLoading ? "Loading..." : listForm?.button} onClick={submitLoading ? () => {} : () => handleOnSubmit()} />
        </Form.Row>
        }
       
      </div>
    </Container>
  );
};

export default PageGrievanceForm;
