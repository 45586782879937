import React from "react";
import "./page404.style.scss"

import LocalstorageService from "../../services/localstorage.service";

const Page404 = () => {

    const gettext = () => {
        if(LocalstorageService.get("lang") === "en")
            return "Page Not Found, please try another page";
        else
            return "Halaman tidak ditemukan, silahkan coba halaman lainnya";
    }

    return(
        <div className="page-404">
            <h1>404</h1>
            <h6>{gettext()}</h6>
        </div>
    )
}

export default Page404;